import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { GenericService } from 'src/app/_services/generic.service';
import { Usuario } from 'src/app/_utils/_tipos/usuario';
import { SessionData } from 'src/app/_utils/session-data';
import { Login } from 'src/app/login/_models/login';
import { UpdatePassword } from 'src/app/panel-inversor/_models/update-password';
import { BrowserCookieService } from 'src/app/_services/cookie-storage.service';
import { CambiarPasswordInversor } from 'src/app/panel-inversor/_models/cambiar-password-inversor';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService extends GenericService<Usuario>
{
  private dataUsuarioRefreshSubject = new Subject<void>();
  private readonly sessionEnvironment = environment.production ? "Sego" : "SegoPre";
  dataUsuarioRefresh$ = this.dataUsuarioRefreshSubject.asObservable();
  session = new Subject<SessionData>();
  subjectLanguage = new Subject<string>();
  constructor(
    @Inject(PLATFORM_ID) private _platformBrowserId: Object, 
    http: HttpClient, 
    private _browserCookieService: BrowserCookieService)
  {
    super(http, "Usuarios", "v1/sociosinversores");
  }

  logOut(redirect: boolean = false): void
  {
    this._browserCookieService.remove('_sessionData' + this.sessionEnvironment);
    this._browserCookieService.remove('_sessionToken' + this.sessionEnvironment);
    if (isPlatformBrowser(this._platformBrowserId))
    {
      //TODO: Llevar a página de cierre de sessión (con un loading mientras cierra) y desde allí ya redirigir a login por router
      // Para asegurar que se redirige después de eliminar las cookies (a veces habia que hacer logout dos veces)
      setTimeout(() => {
        redirect && (window.location.href = window.location.origin + "/login");
      }, 200);
    }
  }

  hasSession(): boolean
  {
    if (isPlatformBrowser(this._platformBrowserId))
    {
      //TODO: @LSegovia Dejarlo activo durante una semana aproximadamente para que se eliminen las cookies antiguas (hasta 16-20 Septiembre 2024)
      const hasOldSession = this._browserCookieService.get('_sessionData') != null;
      hasOldSession && this._browserCookieService.remove("_sessionData");

      const hasSession = this._browserCookieService.get('_sessionData' + this.sessionEnvironment) != null;
      const hasToken = this._browserCookieService.get('_sessionToken' + this.sessionEnvironment) != null;
      const isSessionValid = hasSession && hasToken;

      return isSessionValid;
    }

    return false;
  }

  setSession(data: SessionData): void
  {
    // Separación del token del resto de datos
    const { token, ...dataWithoutToken } = data;
    const sessionData = JSON.stringify(dataWithoutToken);
    const encodedSessionData = btoa(sessionData);

    // Guardo los datos de la sesión en una cookie
    this._browserCookieService.set('_sessionData' + this.sessionEnvironment, encodedSessionData, data.expirationDate);
    this._browserCookieService.set('_sessionToken' + this.sessionEnvironment, token);


    // Emito los datos de la sesión
    this.session.next(dataWithoutToken);
  }

  /**
 * Indica si el token de session del usuarios ha expirado.
 * @returns 
 */
  isAuthenticated(): boolean
  {
    const sessionData = this.getSession();
    // quito dos horas a la enviada por el servidor, ya que a veces da problema con el cambio de hora
    if (sessionData)
    {
      const expirationDate = new Date(sessionData.expirationDate).getTime() - 7200000; // dos horas multiplicado por 60 min, 60 seg, 1000 miliseg
      const today = new Date();
      const isExpirationDateValid = today.getTime() < expirationDate;
      return isExpirationDateValid;
    }
    return false;
  }

  getSession(): SessionData
  {
    if (isPlatformBrowser(this._platformBrowserId))
    {
      if (this.hasSession())
      {
        const sessionData = JSON.parse(atob(this._browserCookieService.get('_sessionData' + this.sessionEnvironment)));
        const sessionToken = this._browserCookieService.get('_sessionToken' + this.sessionEnvironment);
        sessionData.token = sessionToken;
        return sessionData;
      }
    }
    return null;
  }

  login(login: Login): Observable<SessionData>
  {
    return this._http.post<SessionData>(`${this._apiCoreUrl}v1/Login/Inversor`, login)
  }

  cambiarPassword(cambio: CambiarPasswordInversor): Observable<void>
  {
    return this._http.post<void>(`${this._apiCoreUrl}v1/Usuarios/CambiarPassword`, cambio);
  }

  isPasswordChanged(): Observable<boolean>
  {
    return this._http.get<boolean>(`${this._apiCoreUrl}v1/Usuarios/IsPasswordChanged`);
  }

  confirmarCambioPassword(updatePassword: UpdatePassword): Observable<void>
  {
    return this._http.post<void>(`${this._apiCoreUrl}v1/Usuarios/ConfirmarPasswordByHash`, updatePassword);
  }

  refreshDataUsuario(): void
  {
    this.dataUsuarioRefreshSubject.next();
  }
}
